<template>
  <div>
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="dialogVisible = true" style="margin-bottom:20px;">添加线上课程
      </el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <!-- <el-table-column align="center" type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="课程名称" prop="courseName"></el-table-column>
        <el-table-column align="center" label="讲师名称" width="180" prop="lecturerName"></el-table-column>
        <el-table-column align="center" label="课程分类" prop="sortName"></el-table-column>
        <el-table-column align="center" label="课程类型">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type == 0 ? '' : scope.row.type == 1 ? 'success' : 'warning'" disable-transitions>
              {{ scope.row.type == 0 ? '免费课程' : scope.row.type == 1 ? '收费课程' : 'VIP课程' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="课程价格" prop="coursePrice"></el-table-column>
        <el-table-column align="center" label="是否开启活动">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isActivity == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.isActivity == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="课程销量" prop="saleSum"></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="350">
          <template slot-scope="scope">
            <el-button type="success" @click="handlerCatalogue(scope.row)" icon="el-icon-edit" size="mini">目录
            </el-button>
            <el-button type="success" @click="handlerInfo(scope.row)" icon="el-icon-edit" size="mini">详情</el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <!-- 弹出层 -->
    <el-dialog top="4vh" :title="form.id ? '编辑线上课程' : '添加线上课程'" :visible.sync="dialogVisible" width="60%"
               :before-close="handleClose">
      <!-- 表单 -->
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm" :rules="rules">
        <div class="layout_sa">
          <el-form-item label="讲师名称:" prop="lecturerId" label-width="120px">
            <el-select v-model="form.lecturerId" placeholder="请选择讲师" @focus="clearValidate('lecturerId')">
              <el-option v-for="item in lecturerArr" :key="item.value" :label="item.lecturerName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称:" prop="courseName">
            <el-input @focus="clearValidate('courseName')" v-model="form.courseName" placeholder="请输入课程名称"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="课程描述:" prop="courseDesc">
            <el-input v-model="form.courseDesc" @focus="clearValidate('courseDesc')" placeholder="请输入课程描述"
                      autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sa" style="margin-top:20px;">
          <el-form-item label="课程封面图:" label-width="120px" prop="courseImg">
            <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                       :on-success="successImages">
              <img v-if="form.courseImg" :src="form.courseImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="课程分类:" prop="courseSortId">
            <el-select v-model="form.courseSortId" placeholder="请选择课程分类" @focus="clearValidate('courseSortId')">
              <el-option v-for="item in courseArr" :key="item.value" :label="item.sortName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程标签:">
            <el-input v-model="form.label" placeholder="请输入课程标签" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="课程类型:" label-width="120px" style="margin-top:10px;">
          <el-radio-group v-model="form.type">
            <el-radio v-for="item in radioList" :key="item.label" :label="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="课程原价格:" prop="coursePrice" v-if="form.type != 0" style="margin-top:10px;"
                      label-width="120px">
          <el-input type="number" @focus="clearValidate('coursePrice')" v-model="form.coursePrice"
                    placeholder="请输入课程原价格" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="是否开启活动:" label-width="120px" v-if="form.type != 0">
          <el-switch v-model="isAuditionOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
        <el-form-item label="活动价格:" prop="courseActivityPrice" v-if="isAuditionOpen && form.type != 0"
                      label-width="120px">
          <el-input @focus="clearValidate('courseActivityPrice')" type="number" v-model="form.courseActivityPrice"
                    placeholder="请输入活动价格" autocomplete="off"></el-input>
        </el-form-item>
        <div class="layout_sa" style="margin-top:20px;">
          <el-form-item label="开始/结束时间:" prop="activityStartTime" label-width="120px"
                        v-if="isAuditionOpen && form.type != 0">
            <el-date-picker @change="changeData" :picker-options="{disabledDate}" v-model="date" type="datetimerange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="课程详情:" style="margin-top:24px;" label-width="120px" prop="courseText">
          <wang-editor :height="400" ref="editor"/>
        </el-form-item>
        <el-form-item label="状态:" style="margin-top:10px;">
          <el-switch v-model="statusOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WangEditor from "@/components/wangeditor/wangeditor.vue";
import {course} from "@/utils/rules";
import {
  lecturerList,
  courseSortList,
  courseAddOnSet,
  coursePage,
  courseSortDel,
  courseDel,
} from "@/apis/course";

export default {
  components: {WangEditor},
  data() {
    return {
      tableData: [],
      query: {
        current: 1,
        size: 10,
      },
      total: 10,
      dialogVisible: false, // 弹框显示
      rules: course.rules,
      form: {
        lecturerId: "", // 讲师id
        courseName: "", // 课程名称
        courseDesc: "", // 课程描述
        courseImg: "", // 课程封面图片
        coursePrice: "", // 原价
        courseSortId: "", // 课程分类
        type: 0, // 课程类型(0=免费课程，1=收费课程，2=vip课程)
        isActivity: 0, // 是否开启活动(0=开启，1=关闭)
        isAudition: 0, // 是否开启试听（0=开启（前三集免费），1=关闭）
        courseActivityPrice: "", // 活动价格
        activityStartTime: "", // 活动开始时间
        activityEndTime: "", // 活动结束时间
        label: "", //课程标签
        courseText: "", // 课程详情
        status: 0, // status
      },
      lecturerArr: [], // 讲师数组
      courseArr: [], // 课程分类
      radioList: [
        {
          value: "免费课程",
          label: 0,
        },
        {
          value: "收费课程",
          label: 1,
        },
        {
          value: "vip课程",
          label: 2,
        },
      ],
      date: "", // 开始结束时间
      loading: false,
      lecturerName: "", // 教师名称
      isAuditionOpen: true, // 开启活动开关
      statusOpen: true, // 状态开关
    };
  },
  async created() {
    this.getDataList();
    const res = await lecturerList();
    const list = await courseSortList();
    this.lecturerArr = res.data;
    this.courseArr = list.data;
  },
  methods: {
    handlerCatalogue(item) {
      this.$router.push({path: `/catalogueList?id=${item.id}`});
    },

    // 跳转线上课程详情
    handlerInfo(item) {
      this.$router.push({path: `/courseInfo?id=${item.id}`});
    },

    // 添加或编辑
    submit() {
      this.form.courseText = this.$refs.editor.getContent();
      this.$refs.form.validate(async (val) => {
        if (!val) return;
        const {isAuditionOpen, statusOpen} = this;
        this.form.isActivity = isAuditionOpen ? 0 : 1;
        this.form.status = statusOpen ? 0 : 1;
        await courseAddOnSet(this.form);
        this.$message({
          message: this.form.id ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.dialogVisible = false;
        this.getDataList();
        this.clearCen();
      });
    },

    // 获取数据列表
    async getDataList() {
      this.loading = true;
      const res = await coursePage(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },

    handleSizeChange(size) {
      this.query.size = size;
      this.getDataList();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getDataList();
    },

    // 编辑线上课程
    async handlerEdit(item) {
      this.dialogVisible = true;
      const {
        type,
        isActivity,
        isAudition,
        status,
        courseText,
        activityEndTime,
        activityStartTime,
      } = item;
      const newObj = {...item};
      this.form = newObj;
      this.isAuditionOpen = isActivity == 0 ? true : false;
      this.statusOpen = status == 0 ? true : false;
      this.form.type = +type;
      if (activityStartTime) this.date = [activityStartTime, activityEndTime];
      this.$nextTick(() => {
        this.$refs.editor.setContent(courseText);
      });
    },

    // 删除课程类型
    handlerRemove(id) {
      this.$confirm("此操作将永久删除课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            await courseDel({id});
            this.query.current = 1;
            this.$message({message: "删除成功", type: "success"});
            this.getDataList();
          })
          .catch((err) => {
          });
    },

    // 选择时间
    changeData(val) {
      this.form.activityStartTime = this.$moment(val[0]).format(
          "YYYY-MM-DD hh:mm:ss"
      );
      this.form.activityEndTime = this.$moment(val[1]).format(
          "YYYY-MM-DD hh:mm:ss"
      );
    },

    // 取消添加或编辑
    handleClose() {
      this.dialogVisible = false;
      this.clearCen();
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },

    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.courseImg = response.data;
    },

    // 提示
    message(message, type = "success") {
      this.$message({message, type});
    },

    disabledDate(time) {
      // 这里返回true 说明 disabled 不可选
      return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
    },

    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.isAuditionOpen = true;
      this.statusOpen = true;
      this.form.type = 0;
      this.date = "";
      this.$refs.editor.clearContent();
      delete this.form.id; // 删除对象里面的id
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item .el-select {
  width: 210px !important;
}

.el-table {
  margin-top: 0;
}

.name {
  display: flex;
  width: 100px;
  padding-right: 12px;
  justify-content: flex-end;
}

.status {
  display: flex;
  margin-top: 20px;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-right: 18px;
}

.el-form-item {
  flex: 1;
  margin-bottom: 0;
}

/deep/ .el-form-item__label:nth-child(4) {
  line-height: 0 !important;
}

/deep/ .el-form-item .el-input {
  width: 220px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 60px;
  border: 1px dashed #999;
}

.avatar {
  width: 140px;
  height: 60px;
  display: block;
}
</style>